<template>
  <div id="main">
    <div class="main">
      <div class="teachMe" style="position:relative;">
        <transition-group name="fade">

          <div v-if="slide == 9" class="slide coub">
            <svg version="1.1" style=" min-width: 150px; min-height: 150px; " id="L6" xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
            <rect fill="none" stroke="#fff" stroke-width="4" x="25" y="25" width="50" height="50">
            <animateTransform
                attributeName="transform"
                dur="0.5s"
                from="0 50 50"
                to="180 50 50"
                type="rotate"
                id="strokeBox"
                attributeType="XML"
                begin="rectBox.end"/>
            </rect>
              <rect x="27" y="27" fill="#fff" width="46" height="50">
            <animate
                attributeName="height"
                dur="1.3s"
                attributeType="XML"
                from="50"
                to="0"
                id="rectBox"
                fill="freeze"
                begin="0s;strokeBox.end"/>
            </rect>
          </svg>
          </div>

          <div v-if="slide == 8"></div>

          <div v-if="slide == 0">

            <div id="tweetWindow" v-if="tweetWindow.status">
              <div id="content">
                <h1>{{ $t('home.tweetWindow.title') }}</h1>
                <p>{{ $t('home.tweetWindow.desc') }}</p>
                <p class="tw_item">
                  {{
                    $i18n.locale == 'en' ? tweetWindow.sent[tweetWindow.index].text : affirmations_ru[affirmations.indexOf(tweetWindow.sent[tweetWindow.index].text)]
                  }} {{ tweetWindow.sent[tweetWindow.index].emoji }}
                  <a :href="'https://twitter.com/intent/tweet?text=' + encodeURI(tweetWindow.sent[tweetWindow.index].text + ' ' + tweetWindow.sent[tweetWindow.index].emoji + `

Support $TON, Paul and tg with me on ⬛️asu.bot`)" target="_blank" class="tw_button">Tweet this!</a>
                </p>           <img :src="require('@/assets/5-min-cap.png')" style="    max-height: 120px;
    transform: rotate(-15deg);
    pointer-events: none;" alt="">
                <p style=" text-align: right; "> {{ $t('home.tweetWindow.or') }} <span class="small-button"
                                                         @click="back()">{{ $t('home.tweetWindow.close') }}</span></p>


              </div>
            </div>

          </div>

        </transition-group>
      </div>


    </div>
  </div>
</template>

<script>
import affirmations from '@/i18n/affirmations.js'

export default {
  name: 'TweetWindow',
  data() {
    return {
      affirmations: affirmations.en,
      affirmations_ru: affirmations.ru,
      slide: 9,
      tweetWindow: {}
    }
  },
  methods: {
    back() {
      this.$router.push("/")
    },
    changeLang() {
      let newLang;
      this.$i18n.locale == 'en' ? newLang = 'ru' : newLang = 'en'
      this.$i18n.locale = newLang
      this.$store.dispatch('changeLangTonConnect', newLang)
    }
  },
  beforeMount() {
    const tweetData = this.$store.getters.getTweetData;
    if (typeof (tweetData) == 'undefined')
      this.$router.push("/")
    this.tweetWindow = tweetData
    setTimeout(() => {
      this.slide = 8
      setTimeout(() => {
        this.slide = 0
      }, 1000);
    }, 1000);
  }
}
</script>

<style scoped>
.slide.coub {
  min-width: 150px;
  min-height: 150px;
}

.slide.coub {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.slide img {
  max-height: 155px;
  pointer-events: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide {
  position: absolute;
}

.teachMe {
  min-height: 360px;
  min-width: 360px;
}

.next {
  display: flex;
  gap: 4px;
}

div#tweetWindow div#content {
  z-index: 1003;
  /*position: absolute;*/
}

@media screen and (max-width: 296px) {
  .teachMe {
    min-height: 420px !important;
    min-width: 250px !important;
    margin-top: 100px;
  }

}

@media screen and (max-width: 576px) {
  .teachMe {
    min-height: 380px !important;
    min-width: 276px !important;
  }
}
</style>