<template>
  <div id="main">
    <div class="main">

      <div class="teachMe" style="position:relative;">
        <transition-group name="fade">

          <div v-if="slide == 9" class="slide coub">
            <svg version="1.1" style=" min-width: 150px; min-height: 150px; " id="L6" xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
            <rect fill="none" stroke="#fff" stroke-width="4" x="25" y="25" width="50" height="50">
            <animateTransform
                attributeName="transform"
                dur="0.5s"
                from="0 50 50"
                to="180 50 50"
                type="rotate"
                id="strokeBox"
                attributeType="XML"
                begin="rectBox.end"/>
            </rect>
              <rect x="27" y="27" fill="#fff" width="46" height="50">
            <animate
                attributeName="height"
                dur="1.3s"
                attributeType="XML"
                from="50"
                to="0"
                id="rectBox"
                fill="freeze"
                begin="0s;strokeBox.end"/>
            </rect>
          </svg>
          </div>

          <div v-if="slide == 8"></div>

          <div v-if="slide == 0" class="slide">
            <h2>
              {{ $t('welcome.slides[0].title') }}
            </h2>
            <div v-html="$t('welcome.slides[0].description')"></div>
            <div>
              <p style="white-space: nowrap ">{{ $t('welcome.slides[0].pretoken') }} <a class="small-button" target="_blank"
                                                           href="https://tonviewer.com/EQBtoKVySCVEvYFn3oEeiYs8tooYptL75n9j9zSbg2sXOtmM?section=holders">{{ $t('welcome.slides[0].ltoken') }}</a>
              </p>
            </div>
            <img :src="require('@/assets/1' + img_cap + '.png')" alt="">
          </div>

          <div v-if="slide == 1" class="slide">
            <h2>
              {{ $t('welcome.slides[1].title') }}
            </h2>
            <div v-html="$t('welcome.slides[1].description')"></div>
            <img :src="require('@/assets/3' + img_cap + '.png')" alt="">
          </div>

          <div v-if="slide == 2" class="slide">
            <h2>
              {{ $t('welcome.slides[2].title') }}
            </h2>
            <div v-html="$t('welcome.slides[2].description')"></div>
            <img :src="require('@/assets/6' + img_cap + '.png')" alt="">
          </div>

          <div v-if="slide == 3" class="slide">
            <h2>
              {{ $t('welcome.slides[3].title') }}
            </h2>
            <div v-html="$t('welcome.slides[3].description')"></div>
            <img :src="require('@/assets/4' + img_cap + '.png')" alt="">
          </div>
          <div v-if="slide == 4" class="slide">
            <h2>
              {{ $t('welcome.slides[4].title') }}
            </h2>
            <div v-html="$t('welcome.slides[4].description')"></div>
            <img :src="require('@/assets/2' + img_cap + '.png')" alt="">
            <p style="font-size: 12px">{{ $t('welcome.slides[4].ai_generated') }}</p>
          </div>

          <div v-if="slide == 5" class="slide">
            <h2 v-html="$t('welcome.slides[5].title')">
            </h2>
            <div v-html="$t('welcome.slides[5].description')"></div>
            <img :src="require('@/assets/5' + img_cap + '.png')" alt="">
          </div>
        </transition-group>
      </div>

      <div class="next" v-if="slide >= 0 && slide < 6">
        <button class="app-button rect-xl" @click="next()">
           <span class="old-link"  :style="elementStyle" v-if="slide < 5"
                 style=""><img :src="require('@/assets/broken.png')" height="14">next→</span>
          <span v-else>{{ $t('nav.start') }}</span>
        </button>
        <button class="app-button rect-sm" @click="changeLang()"
                style="display: flex;justify-content: center;align-items: center;">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none">
            <path
                d="M21 12C21 16.9706 16.9706 21 12 21M21 12C21 7.02944 16.9706 3 12 3M21 12C21 13.6569 16.9706 15 12 15C7.02944 15 3 13.6569 3 12M21 12C21 10.3431 16.9706 9 12 9C7.02944 9 3 10.3431 3 12M12 21C7.02944 21 3 16.9706 3 12M12 21C10.3431 21 9 16.9706 9 12C9 7.02944 10.3431 3 12 3M12 21C13.6569 21 15 16.9706 15 12C15 7.02944 13.6569 3 12 3M3 12C3 7.02944 7.02944 3 12 3"
                stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WelcomeTo',
  data() {
    return {
      slide: 9,
      img_cap: '-min-cap',
      topValue: 0,
      leftValue: 0,
      rotateValue: 0,
      topRange: [10, 35],
      leftRange: [20, 50],
      rotateRange: [-5, 5],
    }
  },
  methods: {
    getRandomValue(range) {
      const [min, max] = range;
      return Math.random() * (max - min) + min;
    },
    next() {
      this.randomizeStyle();
      if (this.slide < 5) return this.slide++;
      localStorage.setItem("old", true)
      this.$router.push("/")
    },
    changeLang() {
      let newLang;
      this.$i18n.locale === 'en' ? newLang = 'ru' : newLang = 'en'
      this.$i18n.locale = newLang
      this.$store.dispatch('changeLangTonConnect', newLang)
    },
    randomizeStyle() {
      this.topValue = this.getRandomValue(this.topRange);
      this.leftValue = this.getRandomValue(this.leftRange);
      this.rotateValue = this.getRandomValue(this.rotateRange);
    }
  },
  computed: {
    elementStyle() {
      return {
        position: 'absolute',
        top: `${this.topValue}%`,
        left: `${this.leftValue}%`,
        transform: `rotate(${this.rotateValue}deg)`,
      };
    }
  },
  created() {
    this.randomizeStyle();
  },
  beforeMount() {
    for (let i = 1; i <= 6; i++) {
      let image = new Image();
      image.src = require('@/assets/' + i + this.img_cap + '.png');
    }
    setTimeout(() => {
      this.slide = 8
      setTimeout(() => {
        this.slide = 0
      }, 1000);
    }, 1000);
  }
}
</script>

<style scoped>
.hide-all {
  display: none;
}

p, div {
  line-height: 22px;
}

.slide.coub {
  min-width: 150px;
  min-height: 150px;
}

.slide.coub {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.slide img {
  max-height: 155px;
  pointer-events: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide {
  position: absolute;
}

.teachMe {
  min-height: 360px;
  min-width: 476px;
}

.next {
  display: flex;
  gap: 4px;
}

@media screen and (max-width: 296px) {
  .teachMe {
    min-height: 420px !important;
    min-width: 250px !important;
    margin-top: 100px;
  }

}

@media screen and (max-width: 576px) {
  .teachMe {
    min-height: 380px !important;
    min-width: 276px !important;
  }
}
</style>