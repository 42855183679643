<template>
  <div class="about">
    <hide-interface-eye/>
    <floating-numbers/>
    <transition name="fade" mode="out-in">
      <div v-show="!$store.getters.getHideInt">
        <h1>{{ $t('kitties.title') }}</h1>

        <p v-html="$t('kitties.description')"></p>
        <div>📦 x{{ full_pack_kitties }} {{ $t('kitties.packs') }}</div>
        <div class="content">
          <div class="w-div"><span style="margin-bottom:4px">🖼 webp {{
              $t('kitties.cats')
            }} ({{ Object.entries(kitties.webp).length }}/66)<span
                v-if="Object.entries(kitties.webp).length > 0">:</span></span>
            <div class="scroll">
              <div class="pic-content m-scroll"
                   :style="Object.entries(kitties.webp).length > 7 ? 'animation: scroll ' + 1*Object.entries(kitties.webp).length +'s infinite linear;width:' + 34 * 2 * Object.entries(kitties.webp).length + 'px' : 'justify-content: start;'">

              <span v-for="([key, amount], i) in Object.entries(kitties.webp)" :key="i+'webp'+key" style="margin-left: 4px;position:relative">
            <transition name="fade" mode="out-in">
        <span class="pic">
          <img :src="'/webp/'+ key + '.webp'"
               height="30" alt="emoji">
          <span class="pic-counter">x{{ amount }}</span>
        </span>
        </transition>
        </span>
                <span class="dup" v-if="Object.entries(kitties.webp).length > 7">
              <span v-for="([key, amount], i) in Object.entries(kitties.webp)" :key="i+'webpd'+key" style="margin-left: 4px;position:relative">
            <transition name="fade" mode="out-in">
        <span class="pic">
          <img :src="'/webp/'+ key + '.webp'"
               height="30" alt="emoji">
          <span class="pic-counter">x{{ amount }}</span>
        </span>
        </transition>
        </span>
        </span>
              </div>
            </div>
          </div>
        </div>

        <p v-html="$t('kitties.help')"></p>
      </div>
    </transition>
  </div>
</template>


<script>
import FloatingNumbers from "@/components/FloatingNumbers.vue";
import HideInterfaceEye from "@/components/HideInterfaceEye";
import {mapGetters} from "vuex";

export default {
  components: {
    FloatingNumbers,
    HideInterfaceEye,
  },
  data() {
    return {
      full_pack_kitties: '?',
      kitties: {
        webp: {}
      },
    };
  },
  computed: {
    ...mapGetters(['getForceRefreshKitties']),
  },
  methods: {
    async myKitties() {
      if (window.Telegram.WebApp.initData === '') return null;
      const url = 'https://api.asu.bot/q/my_kitties';
      const data = {
        hs: window.Telegram.WebApp.initData
      };
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      };
      await fetch(url, options)
          .then(response => {
            return response.json();
          })
          .then(data => {
            if (data.ok !== false) {
              this.kitties = data.data.kitties
              this.full_pack_kitties = data.data.full_pack_kitties
              this.$store.dispatch('setForceRefreshKitties', false)
            }
          })
          .catch(error => {
            console.error('Error:', error);
          });
    }
  },
  watch: {
    getForceRefreshKitties() {
      this.myKitties()
    }
  },
  mounted() {
    this.myKitties()
    // setInterval(() => {
    //   this.myKitties()
    // }, 10000)
  },
}
</script>

<style scoped>
.pic {
  position: relative;
  width: 30px;
  height: 30px;
  display: inline-block;
}

.w-div {
  margin: 20px 0px;
  gap: 4px;
  display: flex;
  flex-direction: column;
}

.pic-counter {
  position: absolute;
  right: -2px;
  bottom: -2px;
  font-size: 12px;
  background: #0000007d;
  line-height: 12px;
  padding: 1px 3px;
}

.pic-content {
  max-height: 80px;
}

.pic-content {
  /* Обеспечиваем видимость полосы прокрутки */
  overflow-y: auto;
  overflow-x: hidden
}

.pic-content::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5); /* Полупрозрачный белый цвет */
  border-radius: 4px; /* Закругленные углы */
}

.pic-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 1) !important; /* Меняем прозрачность при наведении */
}

.pic-content::-webkit-scrollbar-track {
  background-color: rgba(91, 88, 88, 0.1); /* Цвет фона трека полосы прокрутки */
  border-radius: 4px; /* Закругленные углы */
}
.m-scroll:hover {
  -webkit-animation-play-state: paused !important;
  -moz-animation-play-state: paused !important;
  -o-animation-play-state: paused !important;
  animation-play-state: paused !important;
}

span {
  display: inline-block;
  margin: 0;
  padding: 0;
  color: white;
}

</style>