<template>
  <div id="connect"></div>
</template>

<script>


export default {
  name: 'TonConnect',
  methods: {
    async connectDefine() {
      let lang = localStorage.getItem("lang")
      if(lang == null) lang = 'en'
      this.$store.dispatch('setTonConnect', lang)
      const tonConnectUI = this.$store.getters.getTonConnect;
      tonConnectUI.onStatusChange(async wallet => {
      if (wallet) {
        this.$store.dispatch('setConnectionStatus', true)
        this.$store.dispatch('setUserAddress', wallet.account.address)
      } else {
        this.$store.dispatch('setConnectionStatus', false)
        this.$store.dispatch('setUserAddress', null)
        this.$store.dispatch('setUserWalletAddress', null)
      }
      });
    }
  },
  mounted() {
   this.connectDefine();
  }
}
</script>
