import { createStore } from 'vuex'
import { THEME } from '@tonconnect/ui';
import TonWeb from "tonweb";

export default createStore({
  state: {
    TonConnect: null,
    // mode: 'testnet',
    mode: 'mainnet',
    // contract: 'EQCBiVmHJUeFmLWcQoMFWULGe5pnn-0rLfusGJdsmOhwmUFd',
    contract: 'EQD7ma73hdxrevXYIcQ2ZojGHlOM1ouGw8NEllYQCCKRalxW',
    // jettonMaster: 'kQDMojkUs7prbHMbGqfmhc4i5jXkJu5C7a6klQkudFWU6HaA',
    jettonMaster: 'EQBtoKVySCVEvYFn3oEeiYs8tooYptL75n9j9zSbg2sXOtmM',
    TonWeb: TonWeb,
    lastBlock: 0,
    isConnected: false,
    user: {
      address: null,
      walletAddress: null
    },
    hideInt: false,
    forceRefreshKitties: false
  },
  getters: {
    getTonConnect: (state) => {
      return state.TonConnect;
    },
    getAdder: (state) => {
      return state.mode === 'testnet' ? 'testnet.' : '';
    },
    getMode: (state) => {
      return state.mode;
    },
    getConnectionStatus: (state) => {
      return state.isConnected;
    },
    getTonWeb: (state) => {
      return state.TonWeb;
    },
    getContract: (state) => {
      return state.contract;
    },
    getJettonMaster: (state) => {
      return state.jettonMaster;
    },
    getUserAddress: (state) => {
      return state.user.address;
    },
    getUserWalletAddress: (state) => {
      return state.user.walletAddress;
    },
    getTweetData: (state) => {
      return state.tweetWindow;
    },
    getHideInt: (state) => {
      return state.hideInt;
    },
    getForceRefreshKitties: (state) => {
      return state.forceRefreshKitties;
    },
  },
  mutations: {
    SET_TON_CONNECT(state, lang) {
      // eslint-disable-next-line
      state.TonConnect = new TON_CONNECT_UI.TonConnectUI({
        manifestUrl: 'https://asu.bot/tonconnect-manifest.json',
        buttonRootId: 'connect',
        language: lang,
        uiPreferences: {
            borderRadius: "none",
            theme: THEME.LIGHT,
         },
          actionsConfiguration: {
              skipRedirectToWallet: 'always'
          }
    }); 
    },
    CHANGE_LANG_TONCONNECT(state, lang) {
      localStorage.setItem("lang", lang)
      if(state.TonConnect != null) {
            state.TonConnect.uiOptions = {
              language: lang 
          }
      }
          
    },
    SET_CONNECTION_STATUS(state, value) {
      state.isConnected = value
    },
    SET_USER_ADDRESS(state, address) {
      state.user.address = address
    },
    SET_USER_WALLET_ADDRESS(state, address) {
      state.user.walletAddress = address
    },
    SET_TWEET_DATA(state, tweetData) {
      state.tweetWindow = tweetData
    },
    SET_HIDE_INT(state, v) {
      state.hideInt = v
    },
    SET_FORCE_REFRESH_KITTIES(state, v) {
      state.forceRefreshKitties = v
    },
  },
  actions: {
    setTonConnect({ commit }, value) {
      commit("SET_TON_CONNECT", value);
    },
    changeLangTonConnect({ commit }, value) {
      commit("CHANGE_LANG_TONCONNECT", value);
    },
    setConnectionStatus({ commit }, value) {
      commit("SET_CONNECTION_STATUS", value);
    },
    setUserAddress({ commit }, address) {
      commit("SET_USER_ADDRESS", address);
    },
    setUserWalletAddress({ commit }, address) {
      commit("SET_USER_WALLET_ADDRESS", address);
    },
    setTweetData({ commit }, tweetData) {
      commit("SET_TWEET_DATA", tweetData);
    },
    setHideInt({ commit }, value) {
      commit("SET_HIDE_INT", value);
    },
    setForceRefreshKitties({ commit }, value) {
      commit("SET_FORCE_REFRESH_KITTIES", value);
    },
  },
  modules: {
  }
})
