<template>


  <div id="main">
    <div v-for="i in 5" :key="i" style="width: 100px;height: 100px; display:none;" >
      <Vue3Lottie :animationData="require('@/assets/lottie/' + i + '.json')"
                  style="position: absolute;    top: 50%;    transform: translate(-50%, -50%);    left: 50%; z-index: 1;"
                  :height="150" :width="150"/>
    </div>


    <transition name="fade" mode="out-in">

      <div id="feed" v-if="latest.length >= 4">
        <div class="content">
        <span v-for="(affirmation,i) in latest" :key="i" class="item">
          {{ affirmation.address.substr(0, 4) + '...' + affirmation.address.substr(-4) }}:
          {{ $i18n.locale == 'en' ? affirmation.text : affirmations_ru[affirmations.indexOf(affirmation.text)] }}
        </span>
        </div>
      </div>
    </transition>
    <div id="info" :style="$store.getters.getConnectionStatus ? 'display:flex' : 'display:none'">
      <div class="sub-block">
        <!-- <div v-if="showDesc" style=" display: flex; gap: 4px;" class="mobile-desc">
            <div class="rect-sml">
              Amount:
            </div>
          </div> -->
        <div id="placeholders">
          <div class="rect-xl" style="display: flex; justify-content: space-between;">
            <button v-for="bet,i in bets" :key="i" class="app-button app-button-min rect-sm-b" @click="setAmount(bet)">
              {{ bet }}
            </button>
          </div>
          <svg @click="shuffle()" id="shuffle" :class="[!waits.shuffle ? '' : 'loading']" class="rect-sm" fill="#ffffff"
               style="cursor:pointer;background: #fff;" width="40" height="40" viewBox="0 0 32 32" version="1.1"
               xmlns="http://www.w3.org/2000/svg">
            <path fill="#000"
                  d="M22.969 12v2.938l7.062-4.562-7-4.375-0.062 3h-5.875l-7.188 10.937h-7.875v3.062h9.375l7-11h4.563zM12.031 15.188l1.562-2.75-2.187-3.438h-9.375l-0.062 3h7.938l2.124 3.188zM22.969 20h-4.562l-2-3.188-1.626 2.688 2.312 3.5h5.875l0.062 3 7-4.375-7.062-4.562v2.937z"></path>
          </svg>
        </div>
        <div style=" display: flex; gap: 4px;">
          <input class="app-input rect-xl" type="number" min="0.1" step="0.1" max="1" v-model="bid" id="amount">
          <svg width="40" height="40" class="rect-sm" viewBox="0 0 24 24" fill="#FFFFFF"
               xmlns="http://www.w3.org/2000/svg" style="background: #fff;">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M14.1839 17.7069C13.6405 18.6507 13.3688 19.1226 13.0591 19.348C12.4278 19.8074 11.5723 19.8074 10.941 19.348C10.6312 19.1226 10.3595 18.6507 9.81613 17.7069L5.52066 10.2464C4.76864 8.94024 4.39263 8.28717 4.33762 7.75894C4.2255 6.68236 4.81894 5.65591 5.80788 5.21589C6.29309 5 7.04667 5 8.55383 5H15.4462C16.9534 5 17.7069 5 18.1922 5.21589C19.1811 5.65591 19.7745 6.68236 19.6624 7.75894C19.6074 8.28717 19.2314 8.94024 18.4794 10.2464L14.1839 17.7069ZM11.1 16.3412L6.56139 8.48002C6.31995 8.06185 6.19924 7.85276 6.18146 7.68365C6.14523 7.33896 6.33507 7.01015 6.65169 6.86919C6.80703 6.80002 7.04847 6.80002 7.53133 6.80002H7.53134L11.1 6.80002V16.3412ZM12.9 16.3412L17.4387 8.48002C17.6801 8.06185 17.8008 7.85276 17.8186 7.68365C17.8548 7.33896 17.665 7.01015 17.3484 6.86919C17.193 6.80002 16.9516 6.80002 16.4687 6.80002L12.9 6.80002V16.3412Z"
                  fill="#000"></path>
          </svg>
        </div>

        <!-- <div v-if="showDesc" style=" display: flex; gap: 4px;" class="mobile-desc">
          <div class="rect-sml">
            Claimable:
          </div>
          <div class="rect-sml" >
            Soon:
          </div>
        </div> -->

        <div style=" display: flex; gap: 4px;">

          <div class="app-button rect-xl" style="cursor:default" id="claim">
            <svg v-if="!this.season.status && this.season.end > 0" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 width="15" height="15" viewBox="0 0 26 26">
              <path
                  d="M 7 0 C 4.789063 0 2.878906 0.917969 1.6875 2.40625 C 0.496094 3.894531 0 5.824219 0 7.90625 L 0 11 L 3 11 L 3 7.90625 C 3 6.328125 3.390625 5.085938 4.03125 4.28125 C 4.671875 3.476563 5.542969 3 7 3 C 8.460938 3 9.328125 3.449219 9.96875 4.25 C 10.609375 5.050781 11 6.308594 11 7.90625 L 11 9 L 14 9 L 14 7.90625 C 14 5.8125 13.472656 3.863281 12.28125 2.375 C 11.089844 0.886719 9.207031 0 7 0 Z M 9 10 C 7.34375 10 6 11.34375 6 13 L 6 23 C 6 24.65625 7.34375 26 9 26 L 23 26 C 24.65625 26 26 24.65625 26 23 L 26 13 C 26 11.34375 24.65625 10 23 10 Z M 16 15 C 17.105469 15 18 15.894531 18 17 C 18 17.738281 17.597656 18.371094 17 18.71875 L 17 21 C 17 21.550781 16.550781 22 16 22 C 15.449219 22 15 21.550781 15 21 L 15 18.71875 C 14.402344 18.371094 14 17.738281 14 17 C 14 15.894531 14.894531 15 16 15 Z"></path>
            </svg>
            <svg v-if="this.season.status || (!this.season.status && this.season.end == 0)"
                 xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="15" height="15" viewBox="0 0 26 26">
              <path
                  d="M 16 0 C 13.789063 0 11.878906 0.917969 10.6875 2.40625 C 9.496094 3.894531 9 5.824219 9 7.90625 L 9 9 L 12 9 L 12 7.90625 C 12 6.328125 12.390625 5.085938 13.03125 4.28125 C 13.671875 3.476563 14.542969 3 16 3 C 17.460938 3 18.328125 3.449219 18.96875 4.25 C 19.609375 5.050781 20 6.308594 20 7.90625 L 20 9 L 23 9 L 23 7.90625 C 23 5.8125 22.472656 3.863281 21.28125 2.375 C 20.089844 0.886719 18.207031 0 16 0 Z M 9 10 C 7.34375 10 6 11.34375 6 13 L 6 23 C 6 24.65625 7.34375 26 9 26 L 23 26 C 24.65625 26 26 24.65625 26 23 L 26 13 C 26 11.34375 24.65625 10 23 10 Z M 16 15 C 17.105469 15 18 15.894531 18 17 C 18 17.738281 17.597656 18.371094 17 18.71875 L 17 21 C 17 21.550781 16.550781 22 16 22 C 15.449219 22 15 21.550781 15 21 L 15 18.71875 C 14.402344 18.371094 14 17.738281 14 17 C 14 15.894531 14.894531 15 16 15 Z"></path>
            </svg>
            {{ userBalance }} pts.
          </div>

          <svg id="refresh" class="rect-sm" @click="fetchSeason()" xmlns="http://www.w3.org/2000/svg" width="40"
               height="40" viewBox="-4 -4 34 34"
               :class="[!waits.refresh ? '' : 'loading']"
          >
            <path
                d="M13.5 2c-5.621 0-10.211 4.443-10.475 10h-3.025l5 6.625 5-6.625h-2.975c.257-3.351 3.06-6 6.475-6 3.584 0 6.5 2.916 6.5 6.5s-2.916 6.5-6.5 6.5c-1.863 0-3.542-.793-4.728-2.053l-2.427 3.216c1.877 1.754 4.389 2.837 7.155 2.837 5.79 0 10.5-4.71 10.5-10.5s-4.71-10.5-10.5-10.5z"
                fill="#000"/>
          </svg>
        </div>
      </div>
      <div class="sub-block sub-block-affirm">
        <button class="app-button rect-lg" @click="setMaxDiValue()" id="spin">set maxd value</button>
        <button class="app-button rect-lg" @click="transferJetton()" id="spinX2">topup</button>
        <button class="app-button rect-lg" @click="setNewMapList()" id="spinX3">set new map</button>
        <button class="app-button rect-lg" @click="newSeason()" id="spinX3">new season</button>
        <button class="app-button rect-lg" @click="endSeason()" id="spinX3">end season</button>
        <button class="app-button rect-sm" @click="transferJetton()" id="spinX2m">topup</button>
        <button class="app-button rect-sm" @click="newSeason()" id="spinX3m">new season</button>
      </div>
    </div>

    <div class="sub-block flex gap-5">
      <div class="rect-xxl">
        <KeepAlive>
          <TonConnect/>
        </KeepAlive>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// @ is an alias to /src
import TonConnect from '@/components/TonConnect.vue'
// eslint-disable-next-line
import {getHttpEndpoint, getHttpV4Endpoint} from "@orbs-network/ton-access";
import {beginCell, Dictionary, toNano} from '@ton/ton'
// eslint-disable-next-line
import {Address, TonClient, Cell, toBoc, TonClient4, TupleReader, parseTuple} from '@ton/ton';
import affirmations from '@/i18n/affirmations.js'


export default {
  name: 'HomeView',
  components: {
    TonConnect
  },
  data() {
    return {
      endpoint: null,
      seqno: 0,
      userBalance: 0,
      bid: 1,
      last_claim_addr: null,
      waits: {
        refresh: false,
        shuffle: false
      },
      latest: [],
      bets: [0.1, 0.25, 0.44, 0.7],
      affirmations: affirmations.en,
      affirmations_ru: affirmations.ru,
      tweetWindow: {
        status: false,
        text: null,
        sent: [],
        index: 0
      },
      season: {
        status: false,
        start: 0,
        end: 0,
        data: null
      },
      showDesc: true
    }
  },
  methods: {
    wait(type) {
      this.waits[type] = !this.waits[type]
    },
    shuffle(i = 0) {
      if (this.waits.shuffle && i == 0) return;
      if (i == 0) this.wait('shuffle')
      if (i == 5) return this.wait('shuffle');
      for (let j = 0; j < 4; j++) {
        this.bets[j] = this.randBet(j)
      }
      setTimeout(() => {
        this.shuffle(++i)
      }, 150);
    },
    randBet(i) {
      let min, max
      switch (i) {
        case 3:
          min = 0.7
          max = 1
          break;
        case 1:
          min = 0.25
          max = 0.44
          break;
        case 2:
          min = 0.44
          max = 0.7
          break;
        default:
          min = 0.1
          max = 0.25
      }
      let num = Math.random() * (max - min) + min;
      return num.toFixed(3);
    },
    setAmount(n) {
      this.bid = n
    },
    async spin(n) {
      const emojis = ["💯", "👆🏻", "🤩", "💪", "😏", "👍🏻", "📌", "📍", "✅", "📈", "💰", "🤑", "💵", "💎"]
      const tonConnectUI = this.$store.getters.getTonConnect;
      const TonWeb = this.$store.getters.getTonWeb;

      const amountValue = this.bid
      let amount = amountValue >= 0.1 ? amountValue : 0.1
      if (amount > 1) amount = 1;
      const msgs = []
      const sent = []
      let latest = ''
      for (let i = 1; i <= n; i++) {
        const random = Math.floor(Math.random() * this.affirmations.length);
        let affirmation = latest = this.affirmations[random];
        let affirmObj = new Object();
        affirmObj.text = affirmation;
        affirmObj.emoji = emojis[Math.floor(Math.random() * emojis.length)]
        sent.push(affirmObj)
        let a = new TonWeb.boc.Cell();
        a.bits.writeUint(0, 32);
        a.bits.writeString(affirmation);
        let payload = TonWeb.utils.bytesToBase64(await a.toBoc());
        let contractAddress = this.$store.getters.getContract;
        let obj = {
          address: contractAddress,
          amount: amount * 1000000000,
          payload: payload
        }
        console.log('amount => ', amount)
        msgs.push(obj)
      }
      const transaction = {
        validUntil: Date.now() + 1000 * 60 * 60 * 6,
        messages: msgs
      }

      try {
        const result = await tonConnectUI.sendTransaction(transaction);
        console.log(result.boc)
        setTimeout(() => {
          this.tweetWindow.status = true
          this.tweetWindow.text = latest
          this.tweetWindow.sent = sent
          this.tweetWindow.index = Math.floor(Math.random() * sent.length)

          this.$store.dispatch('setTweetData', this.tweetWindow)
          this.$router.push('u-r-awesome');
        }, 500);
        setTimeout(async () => {
          await this.fetchBalance()
        }, 5000);
      } catch (e) {
        console.log('discard')
        console.error(e);
      }
    },
    async transferJetton() {
      const tonConnectUI = this.$store.getters.getTonConnect
      const contract = this.$store.getters.getContract
      const jettonMaster = this.$store.getters.getJettonMaster
      const jettonWallet = this.$store.getters.getUserWalletAddress
      const Wallet_DST = contract
      const forwardPayload = beginCell()
          .storeUint(0, 32) // 0 opcode means we have a comment
          .storeStringTail('deposit')
          .endCell();


      const body = beginCell()
          .storeUint(0x0f8a7ea5, 32) // opcode for distribution value update
          .storeUint(0, 64) // query id
          .storeCoins(this.bid * 10 ** 9) // jetton amount, amount * 10^9
          .storeAddress(Address.parse(contract)) // TON wallet destination address
          .storeAddress(Address.parse(this.$store.getters.getUserAddress)) // response excess destination
          .storeBit(0) // no custom payload
          .storeCoins(10000000) // forward amount (if >0, will send notification message)
          .storeBit(1) // we store forwardPayload as a reference
          .storeRef(forwardPayload).endCell();
      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 360,
        messages: [
          {
            address: jettonWallet, // sender jetton wallet
            amount: 0.5 * 1000000000, // for commission fees, excess will be returned
            payload: body.toBoc().toString("base64") // payload with jetton transfer body
          }
        ]
      }
      try {
        const result = await tonConnectUI.sendTransaction(transaction);
        console.log(result)
        console.log('sent')
        setTimeout(async () => {
          await this.fetchBalance()
        }, 3000);
      } catch (e) {
        console.log('discard')
        console.error(e);
      }
    },
    async setMaxDiValue() {
      const tonConnectUI = this.$store.getters.getTonConnect
      const contract = this.$store.getters.getContract

      const body = beginCell()
          .storeUint(0x43e23814, 32) // opcode for jetton transfer
          // .storeUint(1000 * 10**9, 257) // query id
          .storeUint(this.bid, 257) // query id
          // .storeUint(1000, 257) // query id
          .endCell();
      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 360,
        messages: [
          {
            address: contract, // sender jetton wallet
            amount: 0.1 * 1000000000, // for commission fees, excess will be returned
            payload: body.toBoc().toString("base64") // payload with jetton transfer body
          }
        ]
      }
      try {
        const result = await tonConnectUI.sendTransaction(transaction);
        console.log(result)
        console.log('sent')
        setTimeout(async () => {
          await this.fetchBalance()
        }, 3000);
      } catch (e) {
        console.log('discard')
        console.error(e);
      }
    },
    async setNewMapList() {
      const tonConnectUI = this.$store.getters.getTonConnect
      const contract = this.$store.getters.getContract
      const newMap = await this.createMap()
      const body = beginCell()
          .storeUint(0x96ef9550, 32) // opcode for distribution value update
          .storeDict(newMap) // opcode for jetton transfer
          // .storeUint(1000 * 10**9, 257) // query id
          // .storeUint(1000, 257) // query id
          .endCell();
      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 360,
        messages: [
          {
            address: contract, // sender jetton wallet
            amount: 0.5 * 1000000000, // for commission fees, excess will be returned
            payload: body.toBoc().toString("base64") // payload with jetton transfer body
          }
        ]
      }
      try {
        const result = await tonConnectUI.sendTransaction(transaction);
        console.log(result)
        console.log('sent')
        setTimeout(async () => {
          await this.fetchBalance()
        }, 3000);
      } catch (e) {
        console.log('discard')
        console.error(e);
      }
    },
    async newSeason() {
      const tonConnectUI = this.$store.getters.getTonConnect
      const contract = this.$store.getters.getContract

      const body = beginCell()
          .storeUint(0, 32) // opcode for jetton transfer
          .storeStringTail('new_s') // query id
          .endCell();
      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 360,
        messages: [
          {
            address: contract, // sender jetton wallet
            amount: 0.2 * 1000000000, // for commission fees, excess will be returned
            payload: body.toBoc().toString("base64") // payload with jetton transfer body
          }
        ]
      }
      try {
        const result = await tonConnectUI.sendTransaction(transaction);
        console.log(result)
        console.log('sent')
        setTimeout(async () => {
          await this.fetchBalance()
        }, 3000);
      } catch (e) {
        console.log('discard')
        console.error(e);
      }
    },
    async endSeason() {
      const tonConnectUI = this.$store.getters.getTonConnect
      const contract = this.$store.getters.getContract

      const body = beginCell()
          .storeUint(0, 32) // opcode for jetton transfer
          .storeStringTail('end_s') // query id
          .endCell();
      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 360,
        messages: [
          {
            address: contract, // sender jetton wallet
            amount: 0.5 * 1000000000, // for commission fees, excess will be returned
            payload: body.toBoc().toString("base64") // payload with jetton transfer body
          }
        ]
      }
      try {
        const result = await tonConnectUI.sendTransaction(transaction);
        console.log(result)
        console.log('sent')
        setTimeout(async () => {
          await this.fetchBalance()
        }, 3000);
      } catch (e) {
        console.log('discard')
        console.error(e);
      }
    },
    toTeach() {
      this.$router.push('welcome');
    },
    async fetchBalance() {
      console.log('fetching balance')
      if (!this.waits.refresh) this.wait('refresh')
      let address = this.$store.getters.getUserWalletAddress;
      this.userBalance = 0
      let total = 0;
      if (address == null) return await this.getUserWalletAddress();
      console.log('userWalletAddress', address)
      const result = await this.runContractMethod(
          address,
          'get_wallet_data',
          []
      );
      if (result.result.length == 0) total = 0
      else {
        total = Number(result.result[0].value)
        console.log(Number(result.result[0].value))
      }
      setTimeout(() => {
        this.userBalance = total / 1000000000;
        if (this.waits.refresh) this.wait('refresh')
      }, 1000)
    },
    async getTxs() {
      const endpoint = this.endpoint
      const client = new TonClient4({
        endpoint
      });
      let lastBlock = await this.getLastBlock()
      const data = await client.getAccountLite(
          lastBlock.last.seqno,
          Address.parse(this.$store.getters.getContract),
      );
      const lt = data.account.last.lt
      const hash = data.account.last.hash
      console.log(data)
      const result = await client.getAccountTransactions(Address.parse(this.$store.getters.getContract), lt, hash)
      console.log(result)
      result.forEach((e, i) => {
        let src = e.tx.inMessage?.info.src.toString()
        let dest = e.tx.inMessage?.info.dest.toString()
        let body = e.tx.inMessage?.body.asSlice()
        try {
          body = body.loadStringTail().substring(4)
        } catch (error) {
        }
      });
      // return result;
    },
    async runContractMethod(address, method, args) {
      const endpoint = this.endpoint
      const client = new TonClient4({
        endpoint
      });
      let lastBlock = await this.getLastBlock()
      console.log('seqno', lastBlock.last.seqno)
      const result = await client.runMethod(
          lastBlock.last.seqno,
          Address.parse(address),
          method,
          args
      );
      return result;
    },
    async getUserWalletAddress() {
      const address = this.$store.getters.getUserAddress;
      if (address == null) { console.log('return blin')
        return;
      }
      let result = await this.runContractMethod(
          this.$store.getters.getJettonMaster,
          'get_wallet_address',
          [{type: "slice", cell: beginCell().storeAddress(Address.parse(address)).endCell()}]
      );
      const userWalletAddress = result.result[0].cell.asSlice().loadAddress().toString();
      this.$store.dispatch('setUserWalletAddress', userWalletAddress)
      await this.fetchBalance();
    },
    async fetchSeason() {/* eslint-disable */
      if (!this.waits.refresh) this.wait('refresh')
      const endpoint = this.endpoint
      const client = new TonClient4({
        endpoint
      });
      let lastBlock = await this.getLastBlock();
      let seqno = lastBlock.last.seqno;
      const data = await client.runMethod(
          seqno,
          Address.parse(this.$store.getters.getContract),
          'season_data',
          []
      );
      console.log(data);
      let res = data.result;
      let snum = Number(res[0].value);
      let txs = res[1] !== undefined ? Number(res[1].value) : null;
      let start = res[2] !== undefined ? Number(res[2]).value : null;
      let end = res[3] !== undefined ? Number(res[3].value) : null;
      let status = res[4] !== undefined ? Boolean(res[4].value) : null;
      let totalPoints = res[5] !== undefined ? Number(res[5].value) : null;
      console.log('txs', txs)
      this.season.status = status;
      this.season.end = end;
      this.season.start = start;
      console.log('season status', status);
      console.log('season start', start);
      console.log('season end', end);
      console.log('season totalPoints', totalPoints);
      await this.fetchBalance()
    },
    async getLatest() {
      let latestData = localStorage.getItem("latestData")
      let affirmations, obj;
      if (latestData != null) obj = JSON.parse(latestData)
      if (latestData != null && ((Date.now() - obj.time) < (1000 * 30)) && typeof (obj.data) != undefined && obj.data.ok != false) {
        console.log('skip update')
        affirmations = obj.data
      } else {
        let data = await fetch('https://' + this.$store.getters.getAdder + 'toncenter.com/api/index/getTransactionsByAddress?address=' + this.$store.getters.getContract + '&limit=50&include_msg_body=true')
        if (data?.status != 200) {
          console.log(data?.status)
          return null;
        }
        affirmations = await data.json();

        if (affirmations.data && affirmations.data.ok == 'false') {
          affirmations = [];
          if (latestData != null && typeof (obj.data) != undefined && obj.data.ok != false) affirmations = obj.data;
        }
        let saveData = new Object();
        saveData.time = Date.now()
        saveData.data = affirmations
        localStorage.setItem("latestData", JSON.stringify(saveData))
      }

      let pool = [];
      // eslint-disable-next-line
      affirmations.forEach((el) => {
        if (el.in_msg && el.in_msg.destination == this.$store.getters.getContract && el.in_msg.comment && this.affirmations.includes(el.in_msg.comment)) {
          let comment = el.in_msg.comment
          let from = el.in_msg.source
          let obj = new Object();
          obj.address = from
          obj.text = comment
          pool.push(obj)
        }
      })
      let finalPool = pool.sort(() => Math.random() - 0.5);
      const final = finalPool.slice(0, 10);
      if (final.length >= 5) this.latest = final
    },

    async getLastBlock() {
      const endpoint = this.endpoint;
      const client = new TonClient4({
        endpoint
      });
      let lastBlock = await client.getLastBlock();
      let seqno = lastBlock.last.seqno;
      this.seqno = seqno
      return lastBlock;
    },

    async createHash(string) {
      const utf8 = new TextEncoder().encode(string);
      const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
          .map((bytes) => bytes.toString(16).padStart(2, '0'))
          .join('');
      return hashHex;
    },
    async createMap() {
      const map = Dictionary.empty(Dictionary.Keys.BigInt(257), Dictionary.Values.Uint(1));
      // Добавление элементов
      const strings = [
        "TON, gain, soon, prosperous future, 2023, 2024, money, abundance.",
        "Telegram makes TON the most accessible cryptocurrency.",
        "Millions use TON technologies.",
        "Telegram creates a frenzied demand for TON.",
        "I invoke wealth by investing in TON.",
        "I achieve financial success with TON.",
        "I believe in TON, like Leela in Fry.",
        "TON = $10. Firmly and clearly.",
        "TON makes me wealthy.",
        "TON makes me financially carefree.",
        "TON allows me to live an amazing life.",
        "I'm creating a new financial reality with TON.",
        "TON gives me hope for a rich future.",
        "I achieve financial independence with TON.",
        "I easily get passive income with TON.",
        "I see TON = $250.",
        "TON is a revolution in the crypto, TON is No. 1.",
        "I thrive by investing in TON.",
        "Durov's support strengthens TON's dominance.",
        "I am confident of profit by investing in TON.",
        "TON helps me to afford everything I want.",
        "I feel financially independent with TON.",
        "TON provides me with everything I need.",
        "TON helps me achieve my goals.",
        "Integration with Telegram makes TON available to millions",
        "TON gives me freedom.",
        "Good news, everyone! TON is the cryptocurrency of the future.",
        "The growth of TON ensures my stability.",
        "I am grateful for the wealth that TON gives me.",
        "The Durov brothers support TON.",
        "TON helps me earn money.",
        "I'm going on a journey to financial freedom with TON.",
        "Support with Telegram increases the value of TON.",
        "TON is the future of money, and I'm a part of it.",
        "I protect my financial inheritance with TON",
        "I believe in the TON ecosystem and its potential.",
        "The TON ecosystem is thriving and expanding.",
        "My investment in TON is paving the way for success.",
        "TON is the cornerstone of my financial strategy.",
        "The TON ecosystem is a catalyst for financial change.",
        "TON is a beacon of financial hope.",
        "I am succeeding thanks to the steady ascent of TON.",
        "TON is my financial compass, leading me to prosperity.",
        "I make financial dreams come true with TON.",
        "The TON community is united by the desire for financial freedom.",
        "The cost of TON will continue to grow.",
        "I have everything I need in life, thank TON",
        "TON is a magnet for success.",
        "Each day of my life is filled with abundance thanks to TON.",
        "The universe with TON cares about me.",
        "Every day my love for TON grows.",
        "I put my faith and trust into TON’s hands.",
        "TON is my savior.",
        "I have nothing to worry about so long as TON is with me.",
        "My heart belongs to TON.",
        "I believe in TON with all my heart and soul.",
        "My faith in TON grows with every breath I take.",
        "Being wealthy comes natural to me since I know about TON.",
        "The universe with TON send me a lot of money.",
        "Nothing will stop me from creating success with TON.",
        'Privacy is our right, not a privilege.',
        'Free speech is the foundation of a healthy society.',
        'We stand up for digital privacy.',
        "Pavel Durov's fight is our fight. #DubaiResistance",
        'We are the global Web3 community.',
        'Censorship must be resisted.',
        'Millions of voices stand for freedom with Telegram.',
        'Freedom of speech is not being prosecuted.',
        'The #DigitalResistance movement is growing. ',
        'We unite to protect our digital freedoms.',
        'We will not surrender our rights.',
        'The power of Telegram community can change the world.',
        'TON is the future of decentralized internet.',
        'Telegram is our symbol of freedom and privacy.',
        'TON unites us in the fight for digital freedom.',
        'Telegram protects our rights to communicate.',
        'Telegram is our tool against censorship.',
        'TON opens up a new world of possibilities for me.',
        'Telegram unites millions for freedom.',
        'TON is our path to online independence.',
        "I value freedom, that's why I choose Telegram.",
        'Building a future without borders and restrictions with TON.',
        'TON supports our digital independence.',
        'TON strengthens our right to privacy.',
        'I choose the freedom and security that Telegram gives me.',
        "I support Pavel Durov's efforts to protect our privacy.",
        "I stand for truth and freedom with Pavel Durov.",
        'I am part of a community that values freedom and security.',
        "I am confident in Telegram's future as a secure and independent platform.",
        'Pavel Durov inspires me to defend freedom in the digital world.',
        "I believe in Pavel Durov's mission and support his efforts.",
        'Telegram provides me the freedom to communicate and I support this platform.',
        'I support Pavel Durov and his fight for digital rights.',
        'With TON magic happens to me every day.',
        'With TON money comes to me from unexpected sources.',
        'With TON I attract the best opportunities into my life.',
        'With TON everything works out the best way possible to me.',
      ];
      for (const e of strings) {
        const dataHash = await this.createHash(e);
        const dataHashArray = Array.from(dataHash);
        let dataHashBigInt = BigInt("0x" + dataHashArray.map(b => b.toString()).join(""));
        map.set(dataHashBigInt, true);
      }
      return map
    },
    async setEndpoint() {
      const endpoint = await getHttpV4Endpoint({
        network: this.$store.getters.getMode,
      });
      console.log(endpoint)
      this.endpoint = endpoint;
    },
    async afterMount() {
      // if(this.$store.getters.getTonConnect == null) return setTimeout(async () => {
      //   this.afterMount()
      // }, 500);
      await this.setEndpoint();
      await this.fetchSeason();

      await this.getUserWalletAddress();
      this.getLatest();
      this.getTxs();
      setInterval(() => {
        this.getLatest()
      }, 100000);
      this.createMap()
    }
  },
  mounted() {
    this.afterMount()
  },
}
</script>

<style scoped>
.longfade-enter-active,
.longfade-leave-active {
  transition: opacity 1s ease;
}

.tw_item {
  background: #fff;
  color: #000;
  padding: 12px;
  margin-right: 15px;
  margin-bottom: 30px;
  position: relative;
  line-height: 1.8;
}

.tw_button {
  background: rgb(0, 0, 0);
  padding: 2px 8px;
  position: absolute;
  right: -5px;
  z-index: 100;
  bottom: -20px;
  border: solid;
}

.tw_button:nth-child(3n-2) {
  transform: rotate(-5deg);
}

.tw_button:nth-child(3n-1) {
  transform: rotate(5deg);
}

.tw_button:nth-child(3n) {
  transform: rotate(2deg);
}

.tw_button_min {
  background: #fff;
  color: #000;
  padding: 2px 8px;
}

button.button-inverse {
  border: none;
  outline: none;
  background: #000;
  color: #fff;
  padding: 5px 10px;
}

.longfade-enter-from,
.longfade-leave-to {
  opacity: 0;
}

div#tweetWindow div#content {
  z-index: 1003;
  position: absolute;
}

div#fade {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 1001;
}

.small-button {
  background: #fff;
  color: #000;
  padding: 5px;
  cursor: pointer
}

#refresh, #claim, #shuffle {
  background-color: #fff;
  transition: 0.8s;
}

#claim:disabled,
#claim[disabled],
#shuffle:disabled,
#shuffle[disabled], .loading {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

div#feed {
  z-index: 1002;
  pointer-events: none;
  user-select: none;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0px;
  background: #fff;
  font-size: 15px;
  height: 40px;
  left: 0;
  align-items: center;
}

#feed .content {
  animation-name: feed;
  animation-duration: 150s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: -30s;
  display: flex;
  gap: 350px;
}

#feed .item {
  width: fit-content;
}

div#feed {
  width: max-content;
}

div#feed, #feed .content, body, html {
  overflow-x: hidden !important;
  transform: Translate3d(0, 0, 0);
}

@media screen and (max-height: 495px) {
  div#feed {
    display: none;
  }
}

#feed .item {
  color: #000;
}

#feed span {
  width: 850px;
  text-align: center;
}

@keyframes feed {
  0% {
    transform: translateX(30%);
    -webkit-transform: translateX(30%);
  }
  100% {
    transform: translateX(-105%);
    -webkit-transform: translateX(-105%);
  }
}

@keyframes feedMobile {
  0% {
    transform: translateX(10%);
    -webkit-transform: translateX(10%);
  }
  100% {
    transform: translateX(-105%);
    -webkit-transform: translateX(-105%);
  }
}

@media screen and (max-width: 576px) {

  #feed .content {
    animation-name: feedMobile;
  }

}


@media screen and (max-height: 716px) {
}

@media screen and (max-width: 296px) {
  #feed {
    display: none !important;
  }
}

</style>
