import {createI18n} from 'vue-i18n'

const messages = {
    en: {
        nav: {
            home: "Home",
            about: "about",
            next: "Next",
            start: "Start ASU",
            soon: "soon"
        },
        home: {
            title: 'Stay optimistic with TON',
            subtitle: 'and ',
            //claim: 'Claim',
            claim: '[ ***** ]',
            affirm: 'Send',
            firstTime: "first time?",
            tweetWindow: {
                title: "Sounds great..",
                desc: "Let's make a tweet!",
                or: "..or",
                close: "Back to ASU"
            },
            to_msg: {
                you: 'You',
                he: '',
                text: ' gets all $ton and $asu.',
                text_me: ' get all $ton and $asu.',
                text_nothing: ' gets all $ton and 0 $asu. Season is over.',
                text_me_nothing: ' get all $ton and 0 $asu. Season is over.',
                enter_address: 'Enter recipient address',
                confirm_address: 'Confirm recipient address'
            },
            tapButtons: "Tap buttons, read",
            offline: "ASU is offline now."
        },
        about: {
            title: "About affirmations",
            description: `<p>Daily positive statements reinforce the team's motivation, investors' commitment, and community's optimism. </p>
        <p>
          Regular practice fills TON's future with immense possibility and inevitable accomplishment.
        </p>
        <p>So, let's start: TON, growth, soon, secure future, 2023, 2024, money, much. 👆🏻</p>`
        },
        kitties: {
            title: "Collect kitties",
          description: "Tap kitties in our innovative app, your collection below <3",
            packs: "full kitties packs",
            cats: "kitties",
            help: "Tap 👁 to hide HUD. It might be helpful."
        },
        welcome: {
            title: "Meet ASU",
            slides: {
                "0": {
                    title: "Support TON",
                    description: `<p>Visit ASU, make an affirmation to support TON and move up the leaderboard. 🏆</p>`,
                    pretoken: "Get $asu - ",
                    ltoken: "leaderboard token"
                },
                "1": {
                    title: "How it works [1/2]",
                    description: `<p>Choose the recipient, set amount of ton, num of messages,</p> 
<p>and send affirmations to the blockchain. 📨 </p>`
                },
                "2": {
                    title: "How it works [2/2]",
                    description: `<p>⚠️ Recipient gets all TON and $asu. </p><p>🧐 Are you sybil? Send it all to yourself and enjoy it. No restrictions, really. 🦄</p>`
                },
                "3": {
                    title: "Math",
                    description: `<p>The number of $asu is determined randomly. 🔮</p>
<p>But the upper bar of randomness increases with the number of attached TON (max. 25%) </p>`
                },
                "4": {
                    title: "Let's enhance the effect",
                    description: `<p>You can send some affirmations to TON Foundation to amplify the effect. 🤷‍♀️</p>`,
                    ai_generated: '* This image is generated by AI. Coincidences are random.'
                },
                "5": {
                    title: "Have fun",
                    description: "<p>Do nothing if it doesn't make you feel good and doesn't align with your goals. </p><p>Your sincere contribution makes a difference!</p>"
                }
            }
        }
    },
    ru: {
        nav: {
            home: "Протокол",
            about: "об ASU",
            next: "Далее",
            start: "Обратно к ASU",
            soon: "сун"
        },
        home: {
            title: 'Будь оптимистом с TON',
            subtitle: 'и ',
            //claim: 'Вывести',
            claim: '[ ***** ]',
            affirm: 'Отправить',
            firstTime: "первый раз?",
            tweetWindow: {
                title: "Звучит просто замечательно",
                desc: "Твитни в X:",
                or: "..или",
                close: "Вернуться к ASU"
            },
            to_msg: {
                you: 'Вы',
                he: '',
                text: ' получит весь $ton и $asu.',
                text_me: ' получите весь $ton и $asu.',
                text_nothing: ' получит весь $ton и 0 $asu. Сезон окончен.',
                text_me_nothing: ' получите весь $ton и 0 $asu. Сезон окончен.',
                enter_address: 'Введите адрес получателя',
                confirm_address: 'Подтвердите адрес получателя'
            },
            tapButtons: "Жмите кнопки, читайте",
            offline: "ASU сейчас недоступен."
        },
        about: {
            title: "Об аффирмациях",
            description: `<p>Ежедневные положительные аффирмации укрепляют мотивацию команды, приверженность инвесторов и оптимизм сообщества.</p> 
        <p>Регулярная практика наполняет будущее TON неизбежными достижениями.</p>
        <p>Давайте начнем: TON, рост, скоро, безбедное будущее, 2023, 2024, деньги, много. 👆🏻</p>`
        },
        kitties: {
            title: "Собирай kitties",
            description: "Нажимай котов в инновационной игре, твои коты ниже <3",
            packs: "полных пачек котов",
            cats: "коты",
            help: "Жми 👁, чтобы скрыть интерфейс. Это может помочь."
        },
        welcome: {
            title: "Знакомься: ASU",
            slides: {
                "0": {
                    title: "Поддержим TON",
                    description: `<p>Посещай ASU, отправляй аффирмации в поддержку TON и продвигайся в лидерборде. 🏆</p>`,
                    pretoken: 'Получай $asu - токен ',
                    ltoken: 'лидерборда'
                },
                "1": {
                    title: "Как это работает [1/2]",
                    description: `<p>Выбери получателя, сумму TON и количество сообщений,</p> 
<p>затем отправь аффирмации ончейн. 📨 </p>`
                },
                "2": {
                    title: "Как это работает [2/2]",
                    description: `<p>⚠️ Получателю придет весь TON и $asu. </p><p>🧐 Ты сибил? Отправляй аффирмации самому себе. Без ограничений. 🦄</p>`
                },
                "3": {
                    title: "Математика",
                    description: `<p>Количество $asu определяется случайно. 🔮</p>
<p>Но верхняя планка рандома увеличивается вместе с количеством прикрепленных TON (макс. 25%) </p>`
                },
                "4": {
                    title: "За себя и за Сашку",
                    description: `<p>Ты можешь отправить несколько аффирмаций в TON Foundation для усиления эффекта. 🤷‍♀️</p>`,
                    ai_generated: '* Это изображение сгенерировано при помощи AI. Совпадения случайны.'
                },
                "5": {
                    title: "Расслабься",
                    description: "<p>Не делай ничего, что не приносит тебе удовольствие и не соответствует твоим целям.  </p><p>Твое искреннее участие ценно.</p>"
                }
            }
        }
    },
}

// 2. Create i18n instance with options
const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'ru',
    messages,
})


export default i18n