<template>
        <transition name="fade" mode="out-in">
            <span :key="locale">
                <slot /> 
            </span>
        </transition>
</template>

  <script>
  export default {
    name: 'TextFade',
    props: {
        keyValue: String,
    },
    computed: {
        locale() {
            return this.keyValue ? this.keyValue : this.$i18n.locale
        },
    }
  }
  </script>