<template>

  <div id="main">
    <floating-numbers/>
    <hide-interface-eye/>
    <transition name="fade" mode="out-in">
      <div id="feed" v-if="latest.length >= 2" class="scroll" style="    min-width: 100%;">
        <div class="content m-scroll"
             :style="latest.length > 1 ? 'animation: scroll ' + 15*latest.length +'s infinite linear;width:100%' : 'justify-content: start;'">
        <span v-for="(affirmation,i) in latest" :key="i" class="item">
          {{ affirmation.address.substr(0, 4) + '...' + affirmation.address.substr(-4) }}:
          {{ $i18n.locale == 'en' ? affirmation.text : affirmations_ru[affirmations.indexOf(affirmation.text)] }}
        </span>
          <span v-for="(affirmation,i) in latest" :key="'d'+i" class="item">
          {{ affirmation.address.substr(0, 4) + '...' + affirmation.address.substr(-4) }}:
          {{ $i18n.locale == 'en' ? affirmation.text : affirmations_ru[affirmations.indexOf(affirmation.text)] }}
        </span>
        </div>
      </div>
    </transition>
    <transition name="fade" mode="out-in">
      <div class="main" v-show="!$store.getters.getHideInt">
        <h1 style="line-height: 42px; position: relative">{{ $t('home.title') }}<br>{{ $t('home.subtitle') }}
          <a :href="'https://tonviewer.com/' + $store.getters.getContract" target="_blank" class="small-button">ASU.</a>
          <div class="mainLottie" style="position: absolute; right: 0px; bottom: -20px;" :height="80" :width="80">
            <img :src="require('@/assets/gif/up.gif')" style="pointer-events: none;height: 50px; filter: none; " alt="">
          </div>
        </h1>
        <div v-if="$store.getters.getConnectionStatus && checkStep(0)" class="sub-block flex gap-4"
             style="position:relative">

          <input type="text" v-model="to" @keyup="fetchDNS()" @focus="to.length > 30 ? to = '' : ''"
                 placeholder="Address or dns"
                 :class="dns.address_alert ? 'app-input rect-lg address-alert' : 'app-input rect-lg'"
                 style="width: 100%; margin-bottom: 4px; cursor: text;padding: 0px 40px 0px 10px;"
                 :style="to.length < 10 ? '' : 'font-size:12px;'">
          <div class="input-placeholders">
            <transition name="fade" mode="out-in">
              <div class="app-button input-preset" v-if="dns.suggested.address != null"
                   @click="setTo(dns.suggested.address)" style="background-color: #000 !important">{{
                  dns.suggested.name
                }}?
              </div>
            </transition>
            <div class="app-button input-preset" @click="presets = !presets"
                 style="background-color:#000 !important"><span
                v-if="!presets">▼</span><span v-if="presets">✖</span></div>
          </div>
        </div>
        <div class="sub-block flex gap-4" v-if="presets && checkStep(0)"
             style="position:relative; margin-bottom: 4px;">
          <div class="" style="display: flex; gap:4px; justify-content: space-between;">
            <div class="rect-xl" style="display:flex;gap: 4px;">
              <button class="app-button app-button-min rect-lg input-preset"
                      :style="'background:' + preset.color + '; color: ' + preset.font_color"
                      v-for="preset,key,i in filteredPresets" @click="setTo(preset.address)" :key="i">
              <span v-if="preset.rly == undefined || preset.rly == false"><span style="font-weight: bold"
                                                                                v-if="to == preset.address">✓ </span>{{
                  key
                }}</span>
                <span v-if="preset.rly == true">rly?</span>
              </button>
            </div>
            <button class="app-button app-button-min rect-sm input-preset"
                    :style="'background:' + input_presets.sd.color + '; color: ' + input_presets.sd.font_color"
                    @click="setTo(input_presets.sd.address)">
            <span v-if="input_presets.sd.rly == undefined || input_presets.sd.rly == false"><span
                style="font-weight: bold" v-if="to == input_presets.sd.address">✓ </span>sd</span>
              <span v-if="input_presets.sd.rly == true">rly?</span>
            </button>
          </div>
          <div class="hidden-sd relative">
            <button class="app-button rect-lg" :class="this.latest.length > 0 ? '' : 'loading'" @click="getRandomAddress()">rand</button>
          </div>
        </div>
        <div v-if="$store.getters.getConnectionStatus" class="app-input mb-4 alert" :style="prebuild.status ? '    padding: 16px 4px 8px 10px  !important;' : ''">

        <span @click="tryAddress()" v-if="checkStep(0)" class="old-link"
              style="position: absolute;right:0; bottom:0"><img :src="require('@/assets/broken.png')" height="14">next→</span>
          <span @click="prebuild.status = false" v-if="prebuild.status" class="old-link o2"
                style="position: absolute;top:0px; left:0">←back<img :src="require('@/assets/broken.png')" height="14"></span>
          <!-- <span class="close">✖</span> -->
          <transition name="fade" mode="out-in">
            <div v-if="!prebuild.status" :key="alertText" class="icon" style="width: min-content; min-height: 25px;">{{ alertText[1] }}</div>
          </transition>
          <transition name="fade" mode="out-in">
            <div :key="alertText" class="text">{{ alertText[0] }}</div>
          </transition>
          <div class="small-button" v-if="prebuild.status && prebuild.affirmations.length > 1">
            <span @click="prebuild.additional < prebuild.affirmations.length - 1 ? prebuild.additional++ : prebuild.additional = 0"
                  v-if="prebuild.status" class="old-link"
                  style="position: absolute;top:0; right:0"><img :src="require('@/assets/broken.png')" height="14">#{{prebuild.additional + 1}}→
              </span>
          </div>
        </div>
        <div @click="affirm()" v-if="prebuild.status && $store.getters.getConnectionStatus" class="app-input mb-4 alert" style="display: flex; justify-content:center;    padding: 0px 10px !important;">

          <span :key="i + 'k'" v-for="i in [0,1]" class="pic">
          <img :src="'/webp/'+ prebuild.kitties[i]"
               height="30" alt="emoji">
          </span>
          <span class="text">{{$t('home.affirm')}}</span>
          <span :key="i + 'k2'" v-for="i in [3,4]" class="pic">
          <img  :src="'/webp/'+ prebuild.kitties[i]"
               height="30" alt="emoji">
          </span>
        </div>

        <div id="info" v-if="$store.getters.getConnectionStatus  && checkStep(1) && prebuild.status === false" style="display:flex">
          <div class="sub-block">
            <div id="placeholders" class="hidden-sd">
              <div class="rect-xl" style="display: flex; justify-content: space-between;">
                <button v-for="(bet,i) in bets" :key="i" class="app-button app-button-min rect-lg"
                        @click="setAmount(bet)">
                  {{ bet }}
                </button>
              </div>
              <button @click="shuffle()" id="shuffle" :class="[!waits.shuffle ? '' : 'loading']" class="rect-sm"
                      style="cursor:pointer;background: #fff;"><img :src="require('@/assets/svg/shuffle.svg')"></button>

            </div>
            <div style=" display: flex; gap: 4px;">

            <span @click="step[0].set = false; step[1].set = false" class="old-link o3"
                  style="position: absolute;">←back<img :src="require('@/assets/broken.png')" height="14"></span>
              <input class="app-input rect-xl" type="number" min="0.2" step="0.1" max="25" id="amount"
                     v-model="step[1].amount">
              <img style="background: #fff;" class="rect-sm" :src="require('@/assets/svg/diamond.svg')">
            </div>


          </div>
          <div class="sub-block sub-block-affirm">
            <button class="app-button rect-lg" @click="spin(1)" id="spin">
              <transition name="fade" mode="out-in">
                <ButtonLottieEffect v-if="effects[1]" id="spin-effect1"/>
              </transition>
              <span class="old-link">🙏next→</span>
            </button>
            <div class="rect-lg hidden-sd flex gap-4">
              <button class="app-button rect-lg" style="text-wrap: nowrap;" @click="spin(2)"
                      id="spinX2">
                <transition name="fade" mode="out-in">
                  <ButtonLottieEffect v-if="effects[2]" id="spin-effect2"/>
                </transition>
                🙏 x2
              </button>
              <button class="app-button rect-lg" style="text-wrap: nowrap;" @click="spin(3)"
                      id="spinX3">
                <transition name="fade" mode="out-in">
                  <ButtonLottieEffect v-if="effects[3]" id="spin-effect3"/>
                </transition>
                🙏 x3
              </button>
            </div>
            <button class="app-button rect-sm" @click="spin(2)" id="spinX2m">
              <transition name="fade" mode="out-in">
                <ButtonLottieEffect v-if="effects[2]" id="spin-effect4"/>
              </transition>
              x2
            </button>
            <button class="app-button rect-sm" @click="spin(3)" id="spinX3m">
              <transition name="fade" mode="out-in">
                <ButtonLottieEffect v-if="effects[3]" id="spin-effect5"/>
              </transition>
              x3
            </button>
          </div>
        </div>

        <div class="sub-block flex gap-4 mb-4 screen-column">
          <div style=" display: flex; gap: 4px;">

            <a href="https://tonviewer.com/EQBtoKVySCVEvYFn3oEeiYs8tooYptL75n9j9zSbg2sXOtmM?section=holders" target="_blank" class="app-button rect-xl" style="cursor:pointer;
    font-weight: bold;" id="claim">

              <img style="pointer-events: none;" :src="require('@/assets/svg/asu.svg')">

              <transition name="fade" mode="out-in">
                  <span :key="userBalance">
                    {{ userBalance }}
                  </span>
              </transition>
              <span>$asu</span>
            </a>
            <button id="refresh" class="rect-sm" @click="fetchSeason()" :class="[!waits.refresh ? '' : 'loading']">
              <img :src="require('@/assets/svg/refresh.svg')">
            </button>
          </div>
          <div class="rect-xxl">
            <TonConnect/>
          </div>
        </div>
        <div style="margin-top:20px;    line-height: 40px;">Gm, <span class="small-button"
                                                                      @click="toTeach()">{{
            $t('home.firstTime')
          }}</span></div>
      </div>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import TonConnect from '@/components/TonConnect.vue'
import ButtonLottieEffect from '@/components/ButtonLottieEffect.vue'
import {getHttpV4Endpoint} from "@orbs-network/ton-access";
import {mapGetters} from 'vuex';

import {Address, beginCell, TonClient4} from '@ton/ton';
import affirmations from '@/i18n/affirmations.js'
import FloatingNumbers from "@/components/FloatingNumbers.vue";
import HideInterfaceEye from "@/components/HideInterfaceEye";

export default {
  name: 'HomeView',
  components: {
    HideInterfaceEye,
    FloatingNumbers,
    TonConnect, ButtonLottieEffect
  },
  data() {
    return {
      endpoint: null,
      seqno: 0,
      userBalance: 0,
      last_claim_addr: null,
      to: '',
      step: {
        0: {
          address: null,
          set: false,
        },
        1: {
          amount: "0.2",
          set: false
        }
      },
      prebuild: {
        status: false,
        affirmations: [],
        amount: 1,
        tx: null,
        sent: [],
        kitties: [],
        additional: 0
      },
      waits: {
        refresh: false,
        shuffle: false,
        tonapi_last_call: 0,
        alert: true
      },
      latest: [],
      presets: false,
      dns: {
        suggested: {
          address: null,
          name: null,
          type: null,
        },
        address_alert: false
      },
      input_presets: {
        me: {address: '', color: '#fff', font_color: "#000"},
        paul: {address: 'UQDYzZmfsrGzhObKJUw4gzdeIxEai3jAFbiGKGwxvxHinf4K', color: '#fff', font_color: "#000"},
        tf: {address: 'EQAFmjUoZUqKFEBGYFEMbv-m61sFStgAfUR8J6hJDwUU09iT', color: '#fff', font_color: "#000"},
        sd: {address: 'UQD_5Gn0KWVVr1nXWuNB_ZJlAWjAdI8LNqO_HTNH7ijeW_R_', color: '#fff', font_color: "#000", rly: false}
      },
      bets: [1, 7, 15],
      affirmations: affirmations.en,
      affirmations_ru: affirmations.ru,
      tweetWindow: {
        status: false,
        text: null,
        sent: [],
        index: 0
      },
      season: {
        status: false,
        start: 0,
        end: 0,
        data: null
      },
      showDesc: true,
      effects: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false
      }
    }
  },
  methods: {
    async affirm() {
      const tonConnectUI = this.$store.getters.getTonConnect;
      try {
        await tonConnectUI.sendTransaction(this.prebuild.tx);
        setTimeout(() => {
          this.tweetWindow.status = true
          this.tweetWindow.text = this.prebuild.affirmations[0]
          this.tweetWindow.sent = this.prebuild.sent
          this.tweetWindow.index = Math.floor(Math.random() * this.prebuild.sent.length)
          this.prebuild.status = false
          this.$store.dispatch('setTweetData', this.tweetWindow)
          this.$router.push('u-r-awesome');
        }, 500);
        setTimeout(async () => {
          await this.fetchBalance()
        }, 5000);
      } catch (e) {
        console.log('discard')
        console.error(e);
      }
    },
    setTo(value = false) {
      this.waits.alert = false
      this.dns.suggested.address = this.dns.suggested.name = this.dns.suggested.type = null;
      if (value == this.input_presets.sd.address && this.input_presets.sd.rly == false && this.to != value) {
        this.to = '';
        return this.input_presets.sd.rly = true
      } else if (this.input_presets.sd.rly) this.input_presets.sd.rly = false
      if (value !== false) this.to = this.step[0].address = value.toString({bounceable: false})
    },
    async fetchDNS($recursible = false) {
      this.setTo()
      if (!$recursible) {
        this.waits.tonapi_last_call = Date.now();
        setTimeout(() => {
          this.fetchDNS(true)
        }, 1050);
      } else {
        if (this.to.length < 3) return;
        if (Date.now() - this.waits.tonapi_last_call < 1050) return;
        this.waits.tonapi_last_call = Date.now();
        let name = this.to.substring(0, 15)
        let data = await fetch('https://tonapi.io/v2/accounts/search?name=' + name)
        let response = await data.json();
        let addresses = response.addresses;
        let i = 0
        while (addresses != undefined && addresses[i] != undefined) {
          let found = addresses[i]
          let name = found.name.split(' ')
          let domain = name[0]
          let type = name[2]
          if (type == 'account') {
            this.dns.suggested.address = Address.parse(found.address)
            this.dns.suggested.name = domain
            this.dns.suggested.type = type
            break;
          } else i++

        }
      }

    },
    wait(type) {
      this.waits[type] = !this.waits[type]
    },
    shuffle(i = 0) {
      if (this.waits.shuffle && i == 0) return;
      if (i == 0) this.wait('shuffle')
      if (i == 5) return this.wait('shuffle');
      for (let j = 0; j < 3; j++) {
        this.bets[j] = this.randBet(j)
      }
      setTimeout(() => {
        this.shuffle(++i)
      }, 150);
    },
    randBet(i) {
      let min, max
      switch (i) {
        case 3:
          min = 10
          max = 25
          break;
        case 1:
          min = 1
          max = 12
          break;
        case 2:
          min = 12
          max = 25
          break;
        default:
          min = 0.2
          max = 1
      }
      let num = Math.random() * (max - min) + min;
      return num.toFixed(2);
    },
    setAmount(n) {
      this.step[1].amount = n;
    },
    async spin(n, next = false) {
      let to = this.tryAddress()
      if (to === false) return;
      if (!next) {
        this.effects[n] = true
        return setTimeout(() => {
          this.effects[n] = false
          this.spin(n, true)
        }, 1200);
      }
      const emojis = ["💯", "👆🏻", "🤩", "💪", "😏", "👍🏻", "📌", "📍", "✅", "📈", "💰", "🤑", "💵", "💎"]
      if (n > 3) n = 3
      if (n < 1) n = 1
      const amountValue = this.step[1].amount
      let amount = amountValue >= 0.2 ? amountValue : 0.2
      if (amount > 25) amount = 25;
      const msgs = []
      // const sent = []
      let latest = ''
      this.prebuild.sent = []
      this.prebuild.kitties = []
      this.prebuild.affirmations = []
      this.prebuild.additional = 0
      for (let i = 1; i <= n; i++) {
        const random = Math.floor(Math.random() * this.affirmations.length);
        let affirmation = latest = this.affirmations[random];
        let affirmObj = new Object();
        affirmObj.text = affirmation;
        affirmObj.emoji = emojis[Math.floor(Math.random() * emojis.length)]
        this.prebuild.sent.push(affirmObj)
        this.prebuild.affirmations.push(latest)
        //queryId:uint64 amount:coins to:address text:^string get_points:bool = Send
        const body = beginCell()
            .storeUint(0x23405ffe, 32) // opcode for Send // queryId:uint64 amount:coins to:address text:^string get_points:bool
            .storeUint(0, 64) // query id
            .storeCoins(amount * 10 ** 9 - 0.1 * 10 ** 9)
            .storeAddress(to)
            .storeRef(
                beginCell()
                    .storeStringTail(affirmation)
                    .endCell()
            )
            .storeUint(1, 1) // true, v = 0 - false
            .endCell();
        let contractAddress = this.$store.getters.getContract;
        let obj = {
          address: contractAddress,
          amount: amount * 10 ** 9,
          payload: body.toBoc().toString("base64")
        }
        msgs.push(obj)
      }
      const transaction = {
        validUntil: Date.now() + 1000 * 60 * 60 * 6,
        messages: msgs
      }
      this.prebuild.tx = transaction
      const pics = {
        webp: 66,
      };
      for(let i = 0; i < 6; i++) {
        let emoji_type = 'webp';
        let emoji_name = Math.floor(Math.random() * pics[emoji_type]) + 1;
        this.prebuild.kitties.push(emoji_name + '.' + 'webp')
      }

      this.prebuild.status = true
    },
    async claim() {
      const tonConnectUI = this.$store.getters.getTonConnect;
      const TonWeb = this.$store.getters.getTonWeb;
      const contract = this.$store.getters.getUserWalletAddress;
      let a = new TonWeb.boc.Cell();
      a.bits.writeUint(0, 32);
      a.bits.writeString("claim");
      let payload = TonWeb.utils.bytesToBase64(await a.toBoc());
      const amount = 0.1
      const msg = [
        {
          address: contract,
          amount: amount * 1000000000,
          payload: payload
        }
      ]
      const transaction = {
        validUntil: Date.now() + 1000 * 60 * 60 * 6,
        messages: msg
      }

      try {
        const result = await tonConnectUI.sendTransaction(transaction);
        console.log(result)
        setTimeout(async () => {
          await this.fetchBalance()
        }, 3000);
      } catch (e) {
        console.log('discard')
        console.error(e);
      }
    },
    toTeach() {
      this.$router.push('welcome');
    },
    async fetchBalance() {
      if (!this.waits.refresh) this.wait('refresh')
      let address = this.$store.getters.getUserWalletAddress;
      this.userBalance = 0
      let total = 0;
      if (address == null) return await this.getUserWalletAddress();
      console.log('userWalletAddress', address)
      const result = await this.runContractMethod(
          address,
          'get_wallet_data',
          []
      );
      if (result.result.length == 0) total = 0
      else {
        total = Number(result.result[0].value)
      }
      setTimeout(() => {
        this.userBalance = Math.floor(total / 1000000000);
        if (this.waits.refresh) this.wait('refresh')
      }, 1000)
    },
    async getTxs() {
      const endpoint = this.endpoint
      const client = new TonClient4({
        endpoint
      });
      let lastBlock = await this.getLastBlock()
      const data = await client.getAccountLite(
          lastBlock.last.seqno,
          Address.parse(this.$store.getters.getContract),
      );
      const lt = data.account.last.lt
      const hash = data.account.last.hash
      const result = await client.getAccountTransactions(Address.parse(this.$store.getters.getContract), lt, hash)
      let pool = []
      let addresses = []
      result.forEach(e => {
        let dest = e.tx.inMessage?.info.dest.toString({bounceable: false})
        let body = e.tx.inMessage?.body.asSlice()
        try {
          body = body.loadStringTail().substring(32 + 15)
          let obj = new Object();
          obj.address = dest
          obj.text = body
          if (this.affirmations.includes(body) && !this.addresses.includes(obj.address)) {
            pool.push(obj)
            addresses.push(obj.address)
          }
        } catch (error) {
          console.log('error')
        }
      });
      return pool;
    },
    async runContractMethod(address, method, args) {
      const endpoint = this.endpoint
      const client = new TonClient4({
        endpoint
      });
      let lastBlock = await this.getLastBlock()
      const result = await client.runMethod(
          lastBlock.last.seqno,
          Address.parse(address),
          method,
          args
      );
      return result;
    },
    async getUserWalletAddress() {
      const address = this.$store.getters.getUserAddress;
      if (address == null) {
        this.waits.refresh = false;
        return null;
      }
      this.input_presets.me.address = Address.parse(address).toString({bounceable: false});
      this.setTo(this.input_presets.me.address)
      if (address == null) return;
      let result = await this.runContractMethod(
          this.$store.getters.getJettonMaster,
          'get_wallet_address',
          [{type: "slice", cell: beginCell().storeAddress(Address.parse(address)).endCell()}]
      );
      const userWalletAddress = result.result[0].cell.asSlice().loadAddress().toString();
      this.$store.dispatch('setUserWalletAddress', userWalletAddress)
      await this.fetchBalance();
    },
    async fetchSeason() {
      if (!this.waits.refresh) this.wait('refresh')
      if (this.endpoint == null) this.endpoint = await this.setEndpoint();
      const endpoint = this.endpoint
      const client = new TonClient4({
        endpoint
      });
      let lastBlock = await this.getLastBlock();
      let seqno = lastBlock.last.seqno;
      const data = await client.runMethod(
          seqno,
          Address.parse(this.$store.getters.getContract),
          'season_data',
          []
      );
      let res = data.result;
      // let txs = res[1] !== undefined ? Number(res[1].value) : null;
      let start = res[2] !== undefined ? Number(res[2]).value : null;
      let end = res[3] !== undefined ? Number(res[3].value) : null;
      let status = res[4] !== undefined ? Boolean(res[4].value) : null;
      this.season.status = status;
      this.season.end = end;
      this.season.start = start;
      console.log('season status', status);
      await this.fetchBalance()
    },
    async getLatest() {
      let latestData = localStorage.getItem("latestData")
      let affirmations, obj;
      if (latestData != null) obj = JSON.parse(latestData)
      if (latestData != null && ((Date.now() - obj.time) < (1000 * 30)) && typeof (obj.data) != undefined && obj.data.ok != false) {
        console.log('skip update')
        affirmations = obj.data
      } else {
        let data = await fetch('https://' + this.$store.getters.getAdder + 'toncenter.com/api/index/getTransactionsByAddress?address=' + this.$store.getters.getContract + '&limit=50&include_msg_body=true')
        if (data?.status != 200) {
          return null;
        }
        affirmations = await data.json();

        if (affirmations.data && affirmations.data.ok == 'false') {
          affirmations = [];
          if (latestData != null && typeof (obj.data) != undefined && obj.data.ok != false) affirmations = obj.data;
        }
        let saveData = new Object();
        saveData.time = Date.now()
        saveData.data = affirmations
        localStorage.setItem("latestData", JSON.stringify(saveData))
      }

      let pool = [];
      // eslint-disable-next-line
      let addresses = [];
      affirmations.forEach((el) => {
        if (el.in_msg && el.in_msg.destination == this.$store.getters.getContract && el.in_msg.body) {
          let comment = atob(el.in_msg.body).substring(66).slice(0, -4)
          if (this.affirmations.includes(comment)) {
            let from = el.in_msg.source
            let obj = new Object();
            obj.address = from
            obj.text = comment
            if (!addresses.includes(obj.address)) {
              pool.push(obj)
              addresses.push(obj.address)
            }
          }
        }
      })
      let finalPool = pool.sort(() => Math.random() - 0.5);
      const final = finalPool.slice(0, 10);
      this.latest = final
    },
    getRandomAddress() {
      if (this.latest && this.latest.length > 0) {
        const randomIndex = Math.floor(Math.random() * this.latest.length);
        return this.setTo(this.latest[randomIndex].address);
      }
      return null; // Если массив пуст или не существует
    },
    async getLastBlock() {
      if (this.endpoint == null) this.endpoint = await this.setEndpoint();
      const endpoint = this.endpoint
      const client = new TonClient4({
        endpoint
      });
      let lastBlock = await client.getLastBlock();
      let seqno = lastBlock.last.seqno;
      this.seqno = seqno
      return lastBlock;
    },
    async setEndpoint() {
      const endpoint = await getHttpV4Endpoint({
        network: this.$store.getters.getMode,
      });
      this.endpoint = endpoint;
      return endpoint
    },
    async afterMount() {
      await this.setEndpoint();
      await this.fetchSeason();
      setTimeout(() => {
        this.getLatest();
      }, 1500)
      await this.getUserWalletAddress();
      setTimeout(() => {
        this.getLatest();
      }, 2500)
      setInterval(() => {
        this.getLatest()
      }, 100000);
    },
    checkStep(n) {
      switch (n) {
        case 0:
          return this.step[0].set === false;
        case 1:
          return this.step[0].set !== false && this.step[1].set === false;
      }
      return true;
    },
    tryAddress() {
      let to;
      try {
        to = Address.parse(this.to)
      } catch (error) {
        this.dns.address_alert = true
        setTimeout(() => {
          this.dns.address_alert = false
        }, 200);
        return false;
      }
      this.setTo(this.to)
      this.step[0].set = true
      return to;
    },
  },
  computed: {
    ...mapGetters(['getConnectionStatus']),
    filteredPresets() {
      return Object.fromEntries(
          Object.entries(this.input_presets).filter(([key, value]) => value.address != '' && key != 'sd')
      );
    },
    alertText() {
      let msg, emoji;
      let tryParse = true;

      try {
        Address.parse(this.to)
      } catch (err) {
        tryParse = false
      }
      let nothing_cap;
      if(this.season.status) nothing_cap = '';
      else nothing_cap = '_nothing'
      if (this.$store.getters.getUserAddress != null && this.to == Address.parse(this.$store.getters.getUserAddress).toString({bounceable: false})) {
        msg = this.$t('home.to_msg.you') + this.$t('home.to_msg.text_me' + nothing_cap);
        emoji = '⚠️'
      } else if (this.to.length > 0 && tryParse) {
        msg = this.$t('home.to_msg.he') + ' ' + this.to.substring(this.to.length - 4) + this.$t('home.to_msg.text' + nothing_cap);
        emoji = '⚠️'
      } else if (this.input_presets.sd.rly) {
        msg = this.$t('home.to_msg.confirm_address');
        emoji = '⚠️'
      } else {
        msg = this.$t('home.to_msg.enter_address')
        emoji = '⚠️'
      }
      let additional = []
      if(this.prebuild.status) {
        msg = this.$i18n.locale == 'en' ? this.prebuild.affirmations[this.prebuild.additional] : this.affirmations_ru[this.affirmations.indexOf(this.prebuild.affirmations[this.prebuild.additional])]
        emoji = '🙏'
        for(let i = 1; i < this.prebuild.affirmations.length; i++) {
          let text = this.$i18n.locale == 'en' ? this.prebuild.affirmations[i] : this.affirmations_ru[this.affirmations.indexOf(this.prebuild.affirmations[i])]
          additional.push(text)
        }
      }
      return [msg, emoji, additional]
    }
  },
  watch: {
    getConnectionStatus(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchSeason()
        this.prebuild.status = false
      }
    }
  },
  mounted() {
    this.afterMount()
  },
}
</script>

<style>
.longfade-enter-active,
.longfade-leave-active {
  transition: opacity 1s ease;
}

.tw_item {
  background: #fff;
  color: #000;
  padding: 12px;
  margin-right: 15px;
  position: relative;
  line-height: 1.8;
}

.tw_button {
  background: rgb(0, 0, 0);
  padding: 2px 8px;
  position: absolute;
  right: -5px;
  z-index: 100;
  bottom: -20px;
  border: solid;
}
.alert .text {
  line-height: 22px;
}
.tw_button:nth-child(3n-2) {
  transform: rotate(-5deg);
}

.tw_button:nth-child(3n-1) {
  transform: rotate(5deg);
}

.tw_button:nth-child(3n) {
  transform: rotate(2deg);
}

.tw_button_min {
  background: #fff;
  color: #000;
  padding: 2px 8px;
}

button.button-inverse {
  border: none;
  outline: none;
  background: #000;
  color: #fff;
  padding: 5px 10px;
}

.longfade-enter-from,
.longfade-leave-to {
  opacity: 0;
}

div#fade {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 1001;
}

.small-button {
  background: #fff;
  color: #000;
  padding: 5px;
  cursor: pointer
}

#refresh, #claim, #shuffle {
  background-color: #fff;
  transition: 0.8s;
}

#claim:disabled,
#claim[disabled],
#shuffle:disabled,
#shuffle[disabled], .loading {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

div#feed {
  z-index: 1002;
  pointer-events: none;
  user-select: none;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0px;
  background: #fff;
  font-size: 15px;
  height: 40px;
  left: 0;
  align-items: center;
}


.info-alert {
  position: absolute;
  z-index: 100;
  background: #000;
  width: 240px;
  border: 1px solid;
  font-size: 12px;
  padding: 10px;
  bottom: -55px;
  left: 5px;
}

.info-alert .text {

}

.info-alert .close {
  position: absolute;
  right: 6px;
  top: 6px;
  background: #fff;
  padding: 0px 2px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
}

#feed .item {
  width: fit-content;
}

div#feed {
  width: max-content;
}

div#feed, #feed .content, body, html {
  overflow-x: hidden !important;
  transform: Translate3d(0, 0, 0);
}

@media screen and (max-height: 495px) {
  div#feed {
    display: none;
  }
}

#feed .item {
  color: #000;
}

#feed span {
  width: 850px;
  text-align: center;
}

@keyframes feed {
  0% {
    transform: translateX(30%);
    -webkit-transform: translateX(30%);
  }
  100% {
    transform: translateX(-105%);
    -webkit-transform: translateX(-105%);
  }
}

@keyframes feedMobile {
  0% {
    transform: translateX(10%);
    -webkit-transform: translateX(10%);
  }
  100% {
    transform: translateX(-105%);
    -webkit-transform: translateX(-105%);
  }
}

@media screen and (max-width: 576px) {
  .mainLottie {
    bottom: -15px !important;
  }

  #feed .content {
    animation-name: feedMobile;
  }


}

@media screen and (max-width: 296px) {
  #feed {
    display: none !important;
  }
}

.input-placeholders {
  position: absolute;
  right: 5px;
  display: flex;
  gap: 4px;
  align-items: center;
  height: calc(100% - 5px);
}

.app-button.input-preset {
  padding: 5px 5px;
  color: whitesmoke;
  min-width: 20px;
  text-align: center;
}

.address-alert {
  animation: shake 0.3s ease-in-out 0s 2;
  animation-iteration-count: 1;
}
</style>
<style scoped>
.m-scroll span.item {
  display: block;
  min-width: 800px;
}
</style>