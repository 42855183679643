<template>
  <div class="hide-all">

    <Vue3Lottie id="hide_app" :animationData="require('@/assets/lottie/eye1.json')" style="opacity: 0.5"
                @click="hideInt()"></Vue3Lottie>
  </div>
</template>

<script>
import {Vue3Lottie} from "vue3-lottie";

export default {
  name: "HideInterfaceEye",
  components: {
    Vue3Lottie
  },
  methods: {
    hideInt() {
      this.$store.dispatch('setHideInt', !this.$store.getters.getHideInt)
      let app = document.querySelector('#app');
      let eye = document.querySelector('#hide_app');
      if (this.$store.getters.getHideInt) {
        eye.style.opacity = 0.85
        app.style.background = 'rgba(0, 0, 0, 0)';
      } else {
        eye.style.opacity = 0.5
        app.style.background = '#000000bd';
      }
    },
  }
}
</script>
