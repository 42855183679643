const affirmations = {
    en: [
        "TON, gain, soon, prosperous future, 2023, 2024, money, abundance.",
        "Telegram makes TON the most accessible cryptocurrency.",
        "Millions use TON technologies.",
        "Telegram creates a frenzied demand for TON.",
        "I invoke wealth by investing in TON.",
        "I achieve financial success with TON.",
        "I believe in TON, like Leela in Fry.",
        "TON = $10. Firmly and clearly.",
        "TON makes me wealthy.",
        "TON makes me financially carefree.",
        "TON allows me to live an amazing life.",
        "I'm creating a new financial reality with TON.",
        "TON gives me hope for a rich future.",
        "I achieve financial independence with TON.",
        "I easily get passive income with TON.",
        "I see TON = $250.",
        "TON is a revolution in the crypto, TON is No. 1.",
        "I thrive by investing in TON.",
        "Durov's support strengthens TON's dominance.",
        "I am confident of profit by investing in TON.",
        "TON helps me to afford everything I want.",
        "I feel financially independent with TON.",
        "TON provides me with everything I need.",
        "TON helps me achieve my goals.",
        "Integration with Telegram makes TON available to millions",
        "TON gives me freedom.",
        "Good news, everyone! TON is the cryptocurrency of the future.",
        "The growth of TON ensures my stability.",
        "I am grateful for the wealth that TON gives me.",
        "The Durov brothers support TON.",
        "TON helps me earn money.",
        "I'm going on a journey to financial freedom with TON.",
        "Support with Telegram increases the value of TON.",
        "TON is the future of money, and I'm a part of it.",
        "I protect my financial inheritance with TON",
        "I believe in the TON ecosystem and its potential.",
        "The TON ecosystem is thriving and expanding.",
        "My investment in TON is paving the way for success.",
        "TON is the cornerstone of my financial strategy.",
        "The TON ecosystem is a catalyst for financial change.",
        "TON is a beacon of financial hope.",
        "I am succeeding thanks to the steady ascent of TON.",
        "TON is my financial compass, leading me to prosperity.",
        "I make financial dreams come true with TON.",
        "The TON community is united by the desire for financial freedom.",
        "The cost of TON will continue to grow.",
        "I have everything I need in life, thank TON",
        "TON is a magnet for success.",
        "Each day of my life is filled with abundance thanks to TON.",
        "The universe with TON cares about me.",
        "Every day my love for TON grows.",
        "I put my faith and trust into TON’s hands.",
        "TON is my savior.",
        "I have nothing to worry about so long as TON is with me.",
        "My heart belongs to TON.",
        "I believe in TON with all my heart and soul.",
        "My faith in TON grows with every breath I take.",
        "Being wealthy comes natural to me since I know about TON.",
        "The universe with TON send me a lot of money.",
        "Nothing will stop me from creating success with TON.",
        'Privacy is our right, not a privilege.',
        'Free speech is the foundation of a healthy society.',
        'We stand up for digital privacy.',
        "Pavel Durov's fight is our fight. #DubaiResistance",
        'We are the global Web3 community.',
        'Censorship must be resisted.',
        'Millions of voices stand for freedom with Telegram.',
        'Freedom of speech is not being prosecuted.',
        'The #DigitalResistance movement is growing. ',
        'We unite to protect our digital freedoms.',
        'We will not surrender our rights.',
        'The power of Telegram community can change the world.',
        'TON is the future of decentralized internet.',
        'Telegram is our symbol of freedom and privacy.',
        'TON unites us in the fight for digital freedom.',
        'Telegram protects our rights to communicate.',
        'Telegram is our tool against censorship.',
        'TON opens up a new world of possibilities for me.',
        'Telegram unites millions for freedom.',
        'TON is our path to online independence.',
        "I value freedom, that's why I choose Telegram.",
        'Building a future without borders and restrictions with TON.',
        'TON supports our digital independence.',
        'TON strengthens our right to privacy.',
        'I choose the freedom and security that Telegram gives me.',
        "I support Pavel Durov's efforts to protect our privacy.",
        "I stand for truth and freedom with Pavel Durov.",
        'I am part of a community that values freedom and security.',
        "I am confident in Telegram's future as a secure and independent platform.",
        'Pavel Durov inspires me to defend freedom in the digital world.',
        "I believe in Pavel Durov's mission and support his efforts.",
        'Telegram provides me the freedom to communicate and I support this platform.',
        'I support Pavel Durov and his fight for digital rights.',
        'With TON magic happens to me every day.',
        'With TON money comes to me from unexpected sources.',
        'With TON I attract the best opportunities into my life.',
        'With TON everything works out the best way possible to me.',
    ],
    ru: [
        "TON, прибыль, скоро, процветающее будущее, 2023, 2024, деньги, изобилие.",
        "Telegram делает TON наиболее доступной криптовалютой.",
        "Миллионы используют технологии TON.",
        "Telegram создает бешеный спрос на TON.",
        "Я призываю богатство, инвестируя в TON.",
        "Я достигаю финансового успеха с TON.",
        "Я верю в TON, как Лила в Фрая.",
        "TON = $10. Твердо и четко.",
        "TON делает меня богатым.",
        "TON позволяет мне жить без финансовых забот.",
        "TON позволяет мне вести удивительную жизнь.",
        "Я создаю новую финансовую реальность с TON.",
        "TON дает мне надежду на богатое будущее.",
        "Я достигаю финансовой независимости с TON.",
        "Я легко получаю пассивный доход с TON.",
        "Я вижу TON = $250.",
        "TON - революция в мире криптовалют, TON - № 1.",
        "Я процветаю, инвестируя в TON.",
        "Поддержка Дуровых укрепляет доминирование TON.",
        "Я уверен в прибыли от инвестиций в TON.",
        "TON помогает мне позволить себе все, что я хочу.",
        "Я чувствую себя финансово независимым с TON.",
        "TON предоставляет мне все, что мне нужно.",
        "TON помогает мне достигать своих целей.",
        "Интеграция с Telegram делает TON доступным для миллионов.",
        "TON дарит мне свободу.",
        "Good news, everyone! TON - криптовалюта будущего.",
        "Рост TON обеспечивает мою стабильность.",
        "Я благодарен за богатство, которое дает мне TON.",
        "Братья Дуровы поддерживают TON.",
        "TON помогает мне зарабатывать деньги.",
        "Я отправляюсь в путешествие к финансовой свободе с TON.",
        "Поддержка от Telegram увеличивает курс TON.",
        "TON - это будущее денег, и я его часть.",
        "Я защищаю свое финансовое наследие с TON.",
        "Я верю в экосистему TON и ее потенциал.",
        "Экосистема TON процветает и расширяется.",
        "Мои инвестиции в TON прокладывают путь к успеху.",
        "TON - это основа моей финансовой стратегии.",
        "Экосистема TON является катализатором финансовых изменений.",
        "TON - маяк финансовой надежды.",
        "Я добиваюсь успеха благодаря устойчивому росту TON.",
        "TON - мой финансовый компас, ведущий меня к процветанию.",
        "Я осуществляю финансовые мечты с TON.",
        "Сообщество TON объединено желанием финансовой свободы.",
        "Стоимость TON будет продолжать расти.",
        "У меня есть все, что мне нужно в жизни, благодаря TON.",
        "TON - это магнит для успеха.",
        "Каждый день моей жизни наполнен изобилием, благодаря TON.",
        "Вселенная вместе с TON заботится обо мне.",
        "Каждый день моя любовь к TON растет.",
        "Я возлагаю свою веру и доверие в руки TON.",
        "TON - мой спаситель.",
        "Мне не о чем беспокоиться, пока TON со мной.",
        "Мое сердце принадлежит TON.",
        "Я верю в TON всем сердцем и душой.",
        "Моя вера в TON растет с каждым моим вдохом.",
        "Быть богатым для меня естественно, так как я знаю о TON.",
        "Вселенная с TON присылают мне много денег.",
        "Ничто не помешает мне создать успех с TON.",
        'Конфиденциальность - это наше право, а не привилегия.',
        'Свобода слова - основа здорового общества.',
        'Мы отстаиваем неприкосновенность цифровой частной жизни.',
        'Борьба Павла Дурова - это наша борьба. #dubairesistance',
        'Мы - глобальное сообщество Web3.',
        'Цензуре нужно противостоять.',
        'Миллионы голосов отстаивают свободу с помощью Telegram.',
        'Свобода слова не преследуется по закону.',
        'Движение #DigitalResistance растет. ',
        'Мы объединяемся для защиты наших цифровых свобод.',
        'Мы не откажемся от своих прав.',
        'Сила сообщества Telegram может изменить мир.',
        'TON — это будущее децентрализованного интернета.',
        'Telegram — наш символ свободы и приватности.',
        'TON объединяет нас в борьбе за цифровую свободу.',
        'Telegram защищает наши права на общение.',
        'Telegram — наш инструмент против цензуры.',
        'TON открывает для меня новый мир возможностей.',
        'Telegram объединяет миллионы ради свободы.',
        'TON — наш путь к независимости в сети.',
        'Я ценю свободу, поэтому выбираю Telegram.',
        'Строю будущее без границ и ограничений с TON.',
        'TON поддерживает нашу цифровую независимость.',
        'TON укрепляет наше право на приватность.',
        'Я выбираю свободу и безопасность, которые дарит мне Telegram.',
        'Я поддерживаю усилия Павла Дурова по защите нашей конфиденциальности.',
        'Я стою за правду и свободу вместе с Павлом Дуровым.',
        'Я являюсь частью сообщества, которое ценит свободу и безопасность.',
        'Я уверен в будущем Telegram как безопасной и независимой платформы.',
        'Павел Дуров вдохновляет меня защищать свободу в цифровом мире.',
        'Я верю в миссию Павла Дурова и поддерживаю его усилия.',
        'Telegram обеспечивает мне свободу общения, и я поддерживаю эту платформу.',
        'Я поддерживаю Павла Дурова и его борьбу за цифровые права.',
        'С TON волшебство происходит со мной каждый день.',
        'С TON деньги приходят ко мне из неожиданных источников.',
        'С TON я привлекаю в свою жизнь самые лучшие возможности.',
        'С TON все складывается наилучшим для меня образом.',
    ]
}

export default affirmations;